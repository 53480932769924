<template>
  <div class="sof-box-full-details">
    <img
      class="sof-box-full-details-image"
      src="~@/assets/svg/landing/all-details-report.svg"
    >
  </div>
</template>

<script>
export default {

}
</script>

<style>
.sof-box-full-details-image {
  width: 100%;
  max-height: 90px;
  transform: scale(1.2) translate(10px, 3px);
}
</style>
